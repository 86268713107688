// add customs styles and general overrides here
// due to the cascading nature of css, if you try to override theme css variables in this file, those changes will not apply. Instead, override css variables in the `override.sass` file
// we recommend not editing this file directly. Instead, create an `assets/sass/_custom.sass` file at the root level of your site.
// if you edit this file directly, you will have to resolve git conflicts when and if you decide to pull changes we make on the theme



// change all element background to the same color
.header, .grid-inverse, .wrap {
  background-color: $page_bg; }


.content, .footer, .footer_inner {
  background-color: $bg; }


.post_content {
  padding-left: 2rem;
  padding-right: 0.5rem;
  background-color: $post_bg;
  a {
      color:  #004080; } }

.content {
  border-style: solid;
  border-color: $theme;
  text-align: left; }

.page_header {
  @include content;
  position: static;
  z-index: 99;
  left: 0;
  text-align: center;
  padding: 0 0rem; }


.banner {
  border-bottom: solid;
  border-color: $theme; }


.posts {
  padding-left: 1rem;
  padding-right: 1rem; }

.post_item {
    background-color: $card_bg; }

.nav {
  color: white;
  &_active {
    background-color: $nav_bg;
    color: white; }

  &_header {
    @include content;
    background-color: $theme;
    padding: 0 0.5rem;
    position: static;
    z-index: 99;
    left: 0;
    text-align: center; }
  &_body {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; }
  &_body_left {
    background-color: $nav_bg; }
  &_parent {
    margin: 0.25rem 0;
    border-radius: 0.5rem;
    width: 65%;
    display: inline;
    text-align: center; }

  &_open + &_sub {
    height: initial;
    z-index: 999;
    color: white;
    @media screen and (min-width: 769px) {
      background-color: $nav_bg; } } }


.sidebar {
  &_inner {
    position: relative;
    &::before {
      content: "";
      padding: 0.5px;
      top: 0;
      bottom: 0;
      // background: var(--light)
      background: linear-gradient(to bottom, var(--haze), var(--light), var(--haze));
      position: absolute;
      left: -2rem {
 } } } }        // display: none


// cookie

* {}


.cookie-container {
  position: fixed;
  bottom: -100%;
  left: 0;
  right: 0;
  background: #2f3640;
  color: #f5f6fa;
  padding: 0 32px;
  box-shadow: 0 -2px 16px rgba(47, 54, 64, 0.39);

  transition: 400ms; }


.cookie-container.active {
  bottom: 0; }


.cookie-container a {
  color: #f5f6fa; }


.cookie-btn {
  background: #e84118;
  border: 0;
  color: #f5f6fa;
  padding: 12px 48px;
  font-size: 18px;
  margin-bottom: 16px;
  border-radius: 8px;
  cursor: pointer; }

